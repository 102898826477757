/*
 * @Author: 张喜琴
 * @Date: 2024-09-09
 * @LastEditors: 张喜琴
 * @LastEditTime: 2024-09-09
 * @Description: 公共方法
 */

/**
     * 表格单条、批量删除 时计算当前分页
     * @param {*} type  1单项删除   2 批量删除
     * @param {*} page  分页页码
     * @param {*} pageSize  分页条数
     * @param {*} total 总条数
     * @param {*} delLength 批量删除的条数     * 单项不用传 批量必传！！！*
     * @returns  pageNum 分页页码
     */

export function tabelDel(type, page, pageSize, total, delLength) {
  var pageNum = page;
  var isLastPage = total > pageSize; //计算是否有多页
  var totalPages = Math.ceil(total % pageSize); //计算出总共多少页
  var isLastOneNum = total % pageSize; //计算出最后一页数据有几条

  if (type === 1) {//单项删除
    if (isLastPage && totalPages == page && isLastOneNum == 1) {//多页、且当前为最后一页，且最后一页只有一条数据时，page-1
      pageNum = page - 1;
    }
  }
  if (type === 2) {//批量删除
    if (isLastPage) {//有多页时
      if (totalPages == page) {//当前为最后一页时
        if (delLength >= isLastOneNum) {//删除条数大于或等于最后一页条数时
          pageNum = page - Math.ceil(delLength / pageSize); //更新page
        }
      } else {//不为最后一页时
        if (page > 1) {//当前不为第一页，也不为最后一页时
          if (total - delLength <= pageSize) {//删除后剩余条数 不足一页时
            pageNum = 1;
          } else {
            var num = total - page * pageSize; //当前页为截止 后面剩余数据条数
            if (delLength > num) {//删除条数> 当前页码后剩余的条数时
              pageNum = page - Math.ceil((delLength - num) / pageSize);
            }
          }
        }
      }
    }
  }
  return pageNum;
}